@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    overflow-x: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #afb2bf;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 3px;
}

*::-webkit-scrollbar-track {
    background: #000814;
}

*::-webkit-scrollbar-thumb {
    background-color: #afb2bf;
    border-radius: 10px;
    visibility: hidden;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

/* Hide default styling of details/summary */
details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

/* https://10015.io/tools/css-loader-generator */
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid #f1f2ff;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
}

.codeblock1 {
    width: 372.95px;
    height: 257.05px;
    border-radius: 100%;
    left: calc(50% - 372.95px / 2 - 76.53px);
    top: calc(50% - 257.05px / 2 - 47.47px);
    background: linear-gradient(123.77deg,
            #8a2be2 -6.46%,
            #ffa500 59.04%,
            #f8f8ff 124.53%);
    opacity: 0.2;
    filter: blur(34px);
    transform: matrix(1, 0, -0.03, 1, 0, 0);
}

.codeblock2 {
    position: absolute;
    width: 372.95px;
    height: 257.05px;
    left: calc(50% - 372.95px / 2 - 76.53px);
    top: calc(50% - 257.05px / 2 - 47.47px);
    border-radius: 100%;
    background: linear-gradient(118.19deg,
            #1fa2ff -3.62%,
            #12d8fa 50.44%,
            #a6ffcb 104.51%);
    opacity: 0.2;
    filter: blur(34px);
    transform: matrix(1, 0, -0.03, 1, 0, 0);
}

.code-border {
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right bottom, #ffffff38, #ffffff00);
    background: linear-gradient(111.93deg,
            rgba(14, 26, 45, 0.24) -1.4%,
            rgba(17, 30, 50, 0.38) 104.96%);
    /* backdrop-filter: blur(26px); */
}

.homepage_bg {
    background: url("../src/assets/Images/bghome.svg");
    /* background-size: contain; */
}

@keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%,
                0 0,
                50% 0%,
                100% 0%,
                100% 0%,
                100% 0%,
                100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%,
                0 0,
                50% 0%,
                100% 0%,
                100% 100%,
                100% 100%,
                100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%,
                0 0,
                50% 0%,
                100% 0%,
                100% 100%,
                50% 100%,
                0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%,
                100% 0,
                100% 0%,
                100% 0%,
                100% 100%,
                50% 100%,
                0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%,
                100% 100%,
                100% 100%,
                100% 100%,
                100% 100%,
                50% 100%,
                0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%,
                50% 100%,
                50% 100%,
                50% 100%,
                50% 100%,
                50% 100%,
                0% 100%);
    }
}

@keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}

/* For conditional coloring of icons in dashboard sidebar */
.active-icon {
    fill: #ffd60a !important;
    color: #ffd60a !important;
}

.inactive-icon {
    fill: #838894;
    color: #838894;
}

/* Buttons */
.yellowButton {
    @apply cursor-pointer rounded-md bg-yellow-50 px-[20px] py-[8px] font-semibold text-richblack-900;
}

/* Swiper CSS Starts */
/* .swiper {
  max-width: 1200px;
  width: 91.66%;
  height: 185px;
  margin: 50px 0px;
} */

.swiper-slide {
    width: 100%;
    height: 100%;
}

/* .swiper-slide {
  @apply bg-richblack-800 text-richblack-25 p-3 text-[14px];
} */
/* Swiper CSS Ends */

.blackButton {
    @apply cursor-pointer rounded-md bg-richblack-800 px-[20px] py-[8px] font-semibold text-richblack-5;
}

.lable-style {
    @apply text-[14px] text-richblack-5;
}

.form-style {
    @apply rounded-lg bg-richblack-700 p-3 text-[18px] leading-[24px] text-richblack-5 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-richblack-400 focus:outline-none;
}

.section_heading {
    @apply text-2xl font-bold text-richblack-5 lg:text-4xl;
}